<template>
  <div class="about">
    <Nav/>
    <div class="background">
        &#8239;
        <div class="pic">
            <img src="/profile.png" alt="">
        </div>
    </div>
    <div class="desc">
        <div class="username">{{ $route.params.data.username }}</div>
        <div class="info">
            <div>Registered {{ formatTimeAgo($route.params.data.dateJoined) }}</div>
        </div>
    </div>
  </div>
</template>

<script>

import { GlobalState } from '../GlobalState';

const formatter = new Intl.RelativeTimeFormat("en", {
  numeric: 'auto'
})

const DIVISIONS = [
  { amount: 60, name: 'seconds' },
  { amount: 60, name: 'minutes' },
  { amount: 24, name: 'hours' },
  { amount: 7, name: 'days' },
  { amount: 4.34524, name: 'weeks' },
  { amount: 12, name: 'months' },
  { amount: Number.POSITIVE_INFINITY, name: 'years' }
]

function formatTimeAgo(date) {
  let duration = (date - new Date()) / 1000

  for (let i = 0; i <= DIVISIONS.length; i++) {
    const division = DIVISIONS[i]
    if (Math.abs(duration) < division.amount) {
      return formatter.format(Math.round(duration), division.name)
    }
    duration /= division.amount
  }
}

export default {
    components:{

    },
    data(){
        return {
            GlobalState
        }
    },
    methods:{
        formatTimeAgo
    }
}
</script>
<style scoped>
.background {
    height: 300px;
    max-height: 50%;
    width: 100%;
    background-color: #9792E3;
    position: relative;
}
.pic {
    position: absolute;
    top: 100%;
    left: 50%;
    --size: 100px;
    width: var(--size);
    height: var(--size);
    transform: translate(-50%,-50%);
    background-color: #48435C;
    border-radius: 8px;
    padding: 5px;
    box-sizing: border-box;
}
.pic img {
    width: 100%;
    height: 100%;
    image-rendering: pixelated;
    border-radius: 4px;
}
.desc {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.username {
    background-color: white;
    display: inline-block;
    color: black;
    font-weight: 500;
    font-size: 20px;
    padding: 6px 12px;
    border-radius: 8px;

    margin-top: 60px;
}
.info {
    margin-top: 10px;
}

</style>